<template >
    <div>
        <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                        {{
                            $t("message.new_m", {
                                m: $t("message.sanction_type_items"),
                            })
                        }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
                  <el-form
                    ref="form"
                    status-icon
                    :model="form"
                    :rules="rules"> 
                        <el-row :gutter="20" v-if="sanction_type_id==2">
                          <el-col :span="12">
                            <div class="app-form__group mb-4">
                              <div class="app-form__group mb-4">
                                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                                  $t("message.position")
                                }}</span>
                                <el-form-item prop="position_id">
                                  <select-position
                                    v-model="form.position_id"
                                    :id="form.position_id"
                                    :size="'medium'"
                                  ></select-position>
                                </el-form-item>
                              </div>
                            </div>
                          </el-col>
                          
                          <el-col :span="12">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                              {{ $t("message.fix") }}
                            </span>
                            <el-form-item
                                prop="fix"
                                class="label_mini">
                                <crm-input  :class="mode ? 'input__day' : 'input__night'"
                                  :inputValue="form.fix"
                                  :placeholder="$t('message.fix')"
                                  v-model="form.fix"
                                  size="medium"
                                  max="100"
                                  :type="'number'"
                                ></crm-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <div class="app-form__group mb-4">
                              <span class="input--label mb-2">
                                {{ $t("message.not_come_work") }}
                              </span>
                              <el-switch :size="'medium'" v-model="form.not_come_work" value="1"> </el-switch>
                            </div>
                          </el-col>
                        </el-row>
                        <el-row :gutter="20" v-else>
                          <el-col :span="12">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                              {{ $t("message.create_sanction_late") }}
                            </span>
                            <el-form-item
                                prop="create_sanction_late"
                                class="label_mini">
                                <crm-input  :class="mode ? 'input__day' : 'input__night'"
                                  :inputValue="form.create_sanction_late"
                                  :placeholder="$t('message.create_sanction_late')"
                                  v-model="form.create_sanction_late"
                                  size="medium"
                                  max="100"
                                  :type="'number'"
                                ></crm-input>
                            </el-form-item>
                          </el-col>
                        </el-row>
                  </el-form>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
/**
 * Drawer chaqirish kerak drawerni yopish uchun
 */
import form from "@/utils/mixins/form";

export default {
    mixins: [form, drawer],
    name: "SanctionTypeItemController",
    data() {
        return {};
    },
    data() {
        return {
          sanction_type_id: this.$route.params.id
        };
    },
    created() {
      this.sanction_type_id=this.$route.params.id;
      console.log(this.sanction_type_id)
    },
    computed: {
        ...mapGetters({
            rules: "sanctionTypeItem/rules",
            model: "sanctionTypeItem/model",
            columns: "sanctionTypeItem/columns",
            mode: "MODE"
        }),
    },
    methods: {
        ...mapActions({
            save: "sanctionTypeItem/store",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.form.sanction_type_id = this.$route.params.id;
                    this.save(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>

